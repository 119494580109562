export const WEBSITE_URL = location.protocol + '//' + location.host
export const SENTRY_DSN_CONSOLE = 'https://8732443ef0104cc49eeb0372e9675fb1@o372989.ingest.sentry.io/6151936'
export const SENTRY_DSN_STORE = 'https://2ef9b15f06164745baa7d25de229bd28@o372989.ingest.sentry.io/6151937'
export const VAPID_PUBLIC_KEY = 'BJmPeVW5dm7ZlH8TpVWfXkmC7GoWGIxjVnDmFFgkq2tMhThx-5GNbjVIySQmAJX3lc4J-aeOOajA1i86jQe3JLg'

export const DOMAIN = WEBSITE_URL.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split(':')[0]
export const TLD = WEBSITE_URL.replace(/^(?:https?:\/\/)?(?:www\.)?(?:store\.)?/i, '').split(':')[0]
export const API_URL = WEBSITE_URL.includes('localhost') ? `${WEBSITE_URL}/w` : `https://cloud.${TLD}`

export const COOKIE_SECURE = window.location.href.startsWith('https://www.tapgiant.com') ||
    window.location.href.startsWith('https://store.tapgiant.com') ||
    window.location.href.startsWith('https://www.tapgiant.ro') ||
    window.location.href.startsWith('https://tapgiant.ro') ||
    window.location.href.startsWith('https://tapgiant.com') ||
    window.location.href.startsWith('https://store.tapgiant.ro')

export const COOKIE_PREFIX = '_tg_'
export const COOKIE_TOKEN = COOKIE_PREFIX + 'tok' // name of cookie used to store token
export const COOKIE_TOKEN_VALIDITY_S = 3600 * 24 * 30 // validity in seconds for COOKIE_TOKEN
export const COOKIE_ACCEPT = COOKIE_PREFIX + 'cookie_accept'
export const COOKIE_CONSENT_NECESSARY = COOKIE_PREFIX + 'cc_necessary'
export const COOKIE_CONSENT_PREFERENCES = COOKIE_PREFIX + 'cc_preferences'
export const COOKIE_CONSENT_VALIDITY_S = 3600 * 24 * 365 // validity in seconds for COOKIE_CONSENT_NECESSARY, COOKIE_CONSENT_PREFERENCES

export const COOKIE_LOCALE = COOKIE_PREFIX + 'i18n'
export const COOKIE_LOCALE_VALIDITY_S = 3600 * 24 * 365

export const COOKIE_SELECTED_WORKPLACE = COOKIE_PREFIX + 'swpl'
export const COOKIE_SELECTED_WORKPLACE_VALIDITY_S = 3600 * 24 * 365

export const COOKIE_RECENT_MEMBERS = COOKIE_PREFIX + 'rmem' // name of cookie that stores array with recent members logged in
export const COOKIE_RECENT_MEMBERS_VALIDITY_S = 3600 * 24 * 30 // validity of COOKIE_RECENT_MEMBERS

export const COOKIE_ACCESS_TOKEN_MEMBER = COOKIE_PREFIX + 'atokm' // name of cookie used to store access token location
export const COOKIE_ACCESS_TOKEN_MEMBER_VALIDITY_S = 3600 * 12 // validity in seconds for COOKIE_ACCESS_TOKEN_MEMBER
export const COOKIE_REFRESH_TOKEN_MEMBER = COOKIE_PREFIX + 'rtokm' // name of cookie used to store REFRESH token location
export const COOKIE_REFRESH_TOKEN_MEMBER_VALIDITY_S = 3600 * 12 // validity in seconds for COOKIE_ACCESS_TOKEN_MEMBER

export const COOKIE_SIDEBAR_OPEN = COOKIE_PREFIX + 'sbo' // name of cookie used to store status of sidebar

export const REGEX_EMAIL = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
export const REGEX_UUID = /\b[a-f0-9]{8}\b-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-\b[a-f0-9]{12}\b/i
export const REGEX_JWT = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/
export const REGEX_HOSTNAME = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,9}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,9})$/

export const DEFAULT_CATEGORY_ID = '00000000-0000-4000-bbbb-000000000000'

export const ALERT_VALIDITY_MS = 5500 // validity of screen pop-ups

export const IMAGE_MAX_FILESIZE = 1024 * 6000 // 6MB
export const IMAGE_EXTENSION = ['jpg', 'png', 'jpeg']
export const IMAGE_TARGET_PX = 1000
export const SPACES_PATH = 'https://tapgiant.ams3.digitaloceanspaces.com/'
export const PRODUCT_IMAGE_LIMIT = 10

export const USERS_PATH = API_URL + '/console/v1/users'
export const ORGANIZATIONS_PATH = API_URL + '/console/v1/organizations'
export const USERS_PUBLIC_PATH = API_URL + '/v1/users'
export const FILES_PATH = API_URL + '/console/v1/files'
export const KEYS_PATH = API_URL + '/console/v1/keys'
export const SESSIONS_PATH = API_URL + '/console/v1/sessions'
export const STATISTICS_PATH = API_URL + '/console/v1/statistics'
export const SUBSCRIPTIONS_PATH = API_URL + '/console/v1/subscriptions'
export const AUDIT_LOGS_PATH = API_URL + '/console/v1/auditlogs'
export const TICKETS_PATH = API_URL + '/console/v1/tickets'
export const SETTINGS_PATH = API_URL + '/console/v1/settings'
export const TIMELINE_PATH = API_URL + '/console/v1/timeline'
export const CONSOLE_INVOICES = API_URL + '/console/v1/invoices'
export const CONSOLE_ITEMS = API_URL + '/console/v1/items'
export const PATH_CUSTOMERS = API_URL + '/console/v1/customers'
export const CATEGORIES_PATH = API_URL + '/console/v1/categories'
export const ITEMS_PATH = API_URL + '/console/v1/items'
export const MEMBERS_PATH = API_URL + '/console/v1/members'
export const LOCATIONS_PATH = API_URL + '/console/v1/locations'
export const ORDERS_PATH = API_URL + '/console/v1/orders'

export const WWW_SUBSCRIPTIONS_PATH = API_URL + '/v1/subscriptions'
export const WWW_MEMBERS_PATH = API_URL + '/v1/members'

export const ORGANIZATION_PROPERTIES = ['send_quota_alert', 'show_limit_error', 'send_limit_alert']
export const BILLING_PROPERTIES = ['name', 'address', 'zipcode', 'state', 'city', 'country', 'phone', 'vat_number', 'trade_registry', 'bank_account', 'bank_name', 'bank_currency']

export const TICKET_STATUS_OPEN = 'open'
export const TICKET_STATUS_RESOLVED = 'resolved'
export const TICKET_STATUS_PENDING_SUPPORT = 'pending_support'
export const TICKET_STATUS_PENDING_CUSTOMER = 'pending_customer'

export const PRODUCT_STATE_ACTIVE_INAPP = 0
export const PRODUCT_STATE_INACTIVE = 1
export const PRODUCT_STATE_ACTIVE_INSHOP = 2
export const PRODUCT_STATE_ACTIVE_FULL = 3
export const PRODUCT_STATE_ARCHIVED = 9

export const CATEGORY_STATE_ACTIVE = 0
export const CATEGORY_STATE_INACTIVE = 1

export const STORE_PLAN_ID_TRIAL = 'store_trial'
export const STORE_PLAN_ID_BASIC = 'store_basic'
export const STORE_PLAN_ID_PRO = 'store_pro'
export const STORE_PLAN_ID_ULTIMATE = 'store_ultimate'
export const STORE_PLAN_ID_MEMBER = 'store_member'

export const BILLING_CYCLE_NA = 'na'
export const BILLING_CYCLE_MONTHLY = 'month'
export const BILLING_CYCLE_YEARLY = 'year'

export const PLAN_ACTIVE = 'active'
export const PLAN_INACTIVE = 'inactive'
export const PLAN_PASTDUE = 'past_due'
export const PLAN_CANCELED = 'canceled'

export const PLAN_STATUS = {
    [PLAN_ACTIVE]: 'ACTIVE',
    [PLAN_PASTDUE]: 'PAST DUE',
    [PLAN_INACTIVE]: 'INACTIVE',
    [PLAN_CANCELED]: 'CANCELED'
}

export const SUBSCRIPTION_PLAN_NAME = {
    [STORE_PLAN_ID_MEMBER]: 'STORE MEMBER',
    [STORE_PLAN_ID_TRIAL]: 'STORE TRIAL',
    [STORE_PLAN_ID_BASIC]: 'STORE BASIC',
    [STORE_PLAN_ID_PRO]: 'STORE PRO',
    [STORE_PLAN_ID_ULTIMATE]: 'STORE ULTIMATE'
}

export const BILLING_CYCLE = {
    [BILLING_CYCLE_NA]: 'N/A',
    [BILLING_CYCLE_MONTHLY]: 'Monthly',
    [BILLING_CYCLE_YEARLY]: 'Yearly'
}

export const MONTH_SHORT = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

export const FAQ = [
    ['Why would I use this product?', 'We respect your privacy. We do not share your data with any third parties. We do not track or spy on you or your users.<br>We are continuously working on creating great and performant solutions.'],
    ['How long does the integration take?', 'You have some clicka clicka to do in your Tapgiant account and some copy-paste. So maybe 10 minutes?!'],
    ['Do you share my data with anyone?', 'No. TapGIANT respects your privacy, doesn\'t spy on you, doesn\'t track you and will never share your data.'],
    ['Can I cancel my subscription?', 'Yes, anytime, without any intervention from customer support.'],
    ['How do I know if I am about to reach my cap?', 'You can activate emails alerts and configure when they should trigger. You can also view realtime status in your account.'],
    ['What happens when I reach my validations cap?', 'You\'ll be notified by email. Validation returns specific error and you can configure if the CAPTCHA will continue to be displayed with an error.'],
    ['Does the CAPTCHA require anything special to run?', 'No. It\'s Vanilla Javascript with no dependencies. Just one JavaScript file, one HTML element and a hidden input and/or subscribe to JavaScript event.'],
    ['Do I need technical knowledge to integrate the CAPTCHA?', 'Yes, you need a minimum understanding of JavaScript and HTTP Requests.'],
    ['Can I change the theme of the CAPTCHA element?', 'No. We\'re continuously improving the CAPTCHA and creating new mechanisms for fighting spam.'],
    ['Can I place my own logo in the CAPTCHA element?', 'Yes, however, the trial and basic plans don\'t have this feature.'],
    ['Where is my data stored?', 'Our servers are located in Europe. Currently Europe has the strongest laws that protect your privacy and data.'],
    ['What is your customer service SLA?', 'Less than 4 hours during business days and 24h at most.'],
    ['Is there a free tier?', 'There is no free tier, however, you can reach out and describe the project you want it for and maybe we can figure something out.'],
    ['Should I use an open source or create my own CAPTCHA?', 'If you have the time to invest in it, why not.']
]

export const STORE_CONFIGURATION_OPTIONS = [
    ['Show Business Details in Order', 'Include your business details when printing the order for your customers. Information includes name, address, phone number.'],
    ['Show Observations Input', 'Include text input for additional order observations provided by customer or sales team members.'],
    ['Show Customer Name', 'Include input for storing customer name when placing an order.'],
    ['Show Customer Address', 'Include input for storing customer address when placing an order. Useful when you deliver the purchased products.'],
    ['Show Customer Email', 'Include input for storing customer name when placing an order. When available you can send mail with order details to your customer.'],
    ['Show Upfront Payment', 'Include input for upfront payment done by the customer.'],
    ['Show Pickup Hour', 'Include input for the pickup hour when placing an order.'],
    ['Show Customer Phone Number', 'Include input for introducing customer phone number.'],
    ['Show Pickup Date', 'Include input for the pickup date when placing an order.']
]

export const UNITS = {
    UNIT_PIECE: 0,
    UNIT_KILOGRAM: 1,
    UNIT_LITER: 2,
    UNIT_OUNCES: 3,
    UNIT_METER_LINEAR: 6,
    UNIT_METER_SQUARE: 7,
    UNIT_CUBIC_METER: 8,
    UNIT_INCH: 10,
    UNIT_FOOT: 11,
    UNIT_YARD: 12,
    UNIT_QUART: 16,
    UNIT_POUNDS: 18,
    UNIT_SQUARE_FEET: 20,
    UNIT_CUBIC_FEET: 21,
    UNIT_SQUARE_INCH: 24,
    UNIT_CUBIC_INCH: 25,
    UNIT_CENTIMETER: 28,
    UNIT_SQUARE_CENTIMETER: 29,
    UNIT_CUBIC_CENTIMETER: 30
}

export const COLORS = {
    red: 'rgba(255, 99, 132, 1)',
    orange: 'rgba(255, 159, 64, 1)',
    brown: 'rgba(150, 75, 0, 1)',
    purple: 'rgba(153, 102, 255, 1)',
    cyan: 'rgba(0, 255, 255, 1)',
    blue: 'rgba(54, 162, 235, 1)',
    pear: 'rgba(209, 226, 49, 1)',
    yellow: 'rgba(255, 205, 86, 1)',
    grey: 'rgba(201, 203, 207, 1)',
    lime: 'rgba(191, 255, 0, 1)',
    green: 'rgba(50, 155, 30, 1)',
    magenta: 'rgba(255, 0, 255, 1)',
    teal: 'rgba(0, 128, 128, 1)',
    violet: 'rgba(127, 0, 255, 1)',
    salmon: 'rgba(250, 128, 114, 1)',
    jade: 'rgba(0, 168, 107, 1)',
    coral: 'rgba(255, 80, 16, 1)',
    pink: 'rgba(253, 108, 158, 1)'
}

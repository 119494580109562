<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col">
                <img alt="404" src="/img/svg/undraw_page_not_found.svg" loading=lazy width="220" class="m-auto d-block">
                <h3 class="text-center my-2">Page Not Found</h3>
                <router-link to="/" class="fsw-none d-block text-center mt-3">Back to homepage</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>
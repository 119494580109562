<template>
    <div class="container overflow-x-hidden">
        <img class="position-absolute top-0 end-0 w-75" src="/img/svg/nav-corner-1.svg" style="z-index: 0">

        <div class="row mb-5 position-relative">
            <div class="col-12 col-lg-6">
                <div class="d-flex h-100 align-items-center mb-5">
                    <div class="pe-lg-5 w-100">
                        <h1 class="text-center text-lg-start fw-bolder mb-3">Contact</h1>
                        <h2 class="text-center text-lg-start fw-lighter h4">Got some questions? Maybe some feedback?</h2>
                        <p class="text-center text-lg-start">Email us <strong class="text-gradient-orange-600">contact[@]tapgiant.com</strong> or use the contact form.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="bg-white rounded shadow-sm p-4">
                    <form @submit.prevent="submit()">
                        <div class="form-group mb-2">
                            <label for="static-name">Name</label>
                            <input v-model="form_name" type="text" class="form-control form-control form-control-outline" id="static-name" placeholder="What's your name?">
                        </div>
                        <div class="form-group mb-2">
                            <!-- Email -->
                            <label for="static-email">Email</label>
                            <span v-if="!is_valid_email" class="small float-end mt-1 text-orange-500">Bad or incomplete email</span>
                            <input v-model="form_email" type="text" class="form-control form-control-lg" id="static-email" placeholder="Where can we reach you?">
                            <small id="static-email-help" class="form-text text-muted">Fill with your email address if you expect a response.</small>
                        </div>
                        <div class="form-group mb-2">
                            <!-- Message -->
                            <label for="static-message" class="form-label">Message</label>
                            <span :class="{'text-red-500': form_message.length < 40 || form_message.length > 500}" class="small float-end mt-1">{{form_message.length}}/500</span>
                            <textarea v-model="form_message" class="form-control" id="static-message" rows="3"></textarea>
                            <small id="static-message-help" class="form-text text-muted">You may use this form to point out issues with tapgiant.com, ask us any questions or send feedback.</small>
                        </div>
                        <div class="form-group mt-4">
                            <p class="fs-6">By clicking "Send" you agree to our <router-link to="privacy" class="text-teal-500">Privacy Policy</router-link> &amp; <router-link to="terms" class="text-teal-500"> Terms of Service</router-link>.</p>
                            <button :disabled="!is_valid_email || loading || !is_valid_message" type="submit" class="btn btn-dark bg-gradient fs-5 btn-block px-5"><small>Send</small></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    REGEX_EMAIL
} from '@/common/constants'

export default {
    components: {
    },
    data: function () {
        return {
            form_name: '',
            form_email: '',
            form_message: '',
            loading: false,
            error: 0
        }
    },
    computed: {
        is_valid_email: function () {
            return this.form_email.length === 0 || REGEX_EMAIL.test(this.form_email)
        },
        is_valid_message: function () {
            return this.form_message.length >= 40 && this.form_message.length <= 500
        }
    },
    watch: {
        form_email: function () {
            this.error = 0
        }
    },
    mounted: async function () {
    },
    methods: {
        escape_param: function (toOutput) {
            return toOutput.replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#x27')
                .replace(/\//g, '&#x2F')
        },
        submit: async function () {
            this.loading = true
            const q = {
                message: this.escape_param(this.form_message)
            }
            if (this.form_name.length > 0) {
                q.name = this.form_name
            }
            if (this.form_email.length > 0) {
                q.email = this.form_email
            }

            const response = await this.$store.dispatch('CONTACT', q)
            if (response.status === 0) {
                this.form_email = ''
                this.form_name = ''
                this.form_message = ''
                // this.$bus.$emit('Notification/show', { msg: 'Mesajul a fost trimis cu succes! Mulțumim!', level: 'success' })
            } else {
                this.error = response.error_code
                switch (response.error_code) {
                default:
                    // this.$bus.$emit('Notification/show', { msg: 'S-a produs o eroare! Încercați din nou în cateva momente sau reîncărcați pagina.', level: 'danger' })
                }
            }
            this.loading = false
        }
    }
}
</script>

<template>
    <div :id="id" :class="[mclass]" class="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div :class="[size]" class="modal-dialog" role="document">
            <div class="modal-content shadow border-0">
                <div class="modal-header px-4 pt-3 pb-3 border-0 bg-gray-100">
                    <h5 class="modal-title text-gray-700">
                        <slot name="header"></slot>
                    </h5>
                    <button v-if="close_button" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>

                <div class="modal-body px-4">
                    <slot name="body"></slot>
                </div>

                <hr v-if="slot_footer">
                <div v-if="slot_footer" class="modal-footer px-4 pt-2 pb-4 gap-4 justify-content-start border-0">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import uuidv4 from '@/common/mixin/uuidv4'
import * as Modal from 'bootstrap/js/dist/modal'

export default {
    mixins: [uuidv4],
    props: {
        mid: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: ''
        },
        close_button: {
            type: Boolean,
            default: true
        },
        mclass: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            id: '',
            handle: null,
            element: null
        }
    },
    computed: {
        slot_footer () {
            return !!this.$slots.footer
        }
    },
    mounted: function () {
        const self = this
        this.element = document.getElementById(this.id)
        this.$bus.$off('Modal/show/'+this.mid)
        this.$bus.$on('Modal/show/'+this.mid, (options) => {
            self.handle = new Modal(self.element, {
                keyboard: false,
                backdrop: 'static'
            })
            self.$bus.$emit('Modal/shown/'+self.mid)
            self.handle.show()
        })
        this.$bus.$off('Modal/hide/'+this.mid)
        this.$bus.$on('Modal/hide/'+this.mid, function () {
            self.handle.hide()
            self.$bus.$emit('Modal/hidden/'+self.mid)
        })
        this.element.addEventListener('hidden.bs.modal', function (e) {
            self.$bus.$emit('Modal/hidden/'+self.mid)
        })
    },
    created: function () {
        this.id = this.uuidv4()
    },
    methods: {
        close: function () {
            this.$bus.$emit('Modal/close')
        }
    }
}
</script>

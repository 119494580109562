<template>
    <div class="container-fluid">
        <div class="row mb-5 position-relative">
            <div class="col">
                <h1 class="text-center fw-bolder text-gradient-pear-700 mb-3">Legal Entity</h1>
                <div class="text-center pt-3">
                    <p class="m-0">Name</p>
                    <p class="fw-bolder">TAPGIANT S.R.L.</p>
                    <p class="m-0">CUI</p>
                    <p class="fw-bolder">45932721</p>
                    <p class="m-0">Registry</p>
                    <p class="fw-bolder">J22/1182/2022</p>
                    <p class="m-0">Address</p>
                    <p class="fw-bolder">City Iași, State Iași, Romania, EU</p>
                    <p class="m-0">Inquiries</p>
                    <p class="fw-bolder">contact[at]tapgiant.com</p>
                </div>
            </div>
        </div>
    </div>
</template>

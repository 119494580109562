import {
    ITEMS_PATH,
    CONSOLE_ITEMS
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'
import SerializeQuery from '@/common/mixin/SerializeQuery'

const store = {
    state: {
        items: [],
        paginator: {
            limit: 30,
            total: 0,
            count: 0,
            trash: 0,
            page: 1
        }
    },
    mutations: {
        SET_ITEMS (state, value) {
            state.items = value
        },
        SET_PAGINATOR (state, value) {
            state.paginator = value
        },
        SET_PAGE (state, value) {
            state.paginator.page = value
        }
    },
    actions: {
        async GET_SUPPLIES (context, data) {
            const opt = {}
            if (data.format === 'xlsx') {
                opt.responseType = 'blob'
            }
            const qs = SerializeQuery.methods.serialize_query(data)
            let response
            try {
                response = await axios.get(`${CONSOLE_ITEMS}/supplies?${qs}`, opt)
            } catch (error) {
                return error.response
            }
            return response
        },
        async PAGINATE (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${ITEMS_PATH}?${qs}`)
            } catch (error) {
                return error.response
            }

            context.commit('SET_ITEMS', result.data.items)
            context.commit('SET_PAGINATOR', {
                limit: result.data.limit,
                total: result.data.total,
                count: result.data.count,
                trash: result.data.trash,
                page: result.data.page
            })
        },
        async GET (context, item_id) {
            let result
            try {
                result = await axios.get(`${ITEMS_PATH}/${item_id}`)
            } catch (error) {
                return error.response
            }
            return result
        },
        async CREATE (context, data) {
            let response = {}
            try {
                response = await axios.post(`${ITEMS_PATH}`, data)
            } catch (error) {
                return error.response
            }
            return response
        },
        async UPDATE (context, data) {
            let response = {}
            try {
                response = await axios.put(`${ITEMS_PATH}/${data.id}`, data.params)
            } catch (error) {
                return error.response
            }
            return response
        },
        async DELETE_ITEM (context, data) {
            let response = {}
            try {
                response = await axios.delete(`${ITEMS_PATH}/${data.item_id}`)
            } catch (error) {
                return error.response.data
            }
            return response.data
        },
        async DELETE_IMAGE (context, data) {
            let response = {}
            try {
                response = await axios.delete(`${ITEMS_PATH}/${data.item_id}/image/${data.image_id}`)
            } catch (error) {
                return error.response
            }
            return response
        },
        async UPDATE_DELIVERY_ITEM (context, data) {
            let response = {}
            try {
                response = await axios.put(`${ITEMS_PATH}/${data.id}/delivery`, data.params)
            } catch (error) {
                return error.response
            }
            return response
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)

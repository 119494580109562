<template>
    <footer class="container mt-auto">
        <div class="row position-relative">
            <div class="col">
                <p class="text-center small py-2 mb-0">
                    <span class="ml-2 mr-2 text-gray-600 mx-2 text-nowrap">Copyright © 2022 - {{year}} Tapgiant</span>
                    <router-link to="/contact" class="mx-2" exact>
                        {{$t('footer.contact')}}
                    </router-link>
                    <router-link to="/terms" class="mx-2" exact>
                        {{$t('footer.terms_conditions')}}
                    </router-link>
                    <router-link to="/privacy" class="mx-2" exact>
                        {{$t('footer.privacy_policy')}}
                    </router-link>
                    <router-link to="/legal" class="mx-2" exact>
                        {{$t('footer.legal_entity')}}
                    </router-link>
                    <span class="dropdown d-inline-block mx-2">
                        <a class="nav-link dropdown-toggle me-4" href="#" id="dropdown03" data-bs-toggle="dropdown" aria-expanded="false">
                            <span v-if="locale === 'ro'">
                                <img src="/img/flag/ro.png" class="me-2">
                                <span class="align-middle">RO</span>
                            </span>
                            <span v-if="locale === 'en'" class="d-inline-block align-middle">
                                <img src="/img/flag/us.png" class="me-2">
                                <span class="align-middle">EN</span>
                            </span>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdown03">
                            <li><span v-if="locale !== 'ro'" @click="set_locale('ro')" class="pe-pointer mx-2 py-2 d-block"><img src="/img/flag/ro.png" class="me-2"><span class="align-middle">RO</span></span></li>
                            <li><span v-if="locale !== 'en'" @click="set_locale('en')" class="pe-pointer mx-2 py-2 d-block"><img src="/img/flag/us.png" class="me-2"><span class="align-middle">EN</span></span></li>
                        </ul>
                    </span>
                </p>
            </div>
        </div>
        <vue-cookie-accept-decline :debug="false" :disableDecline="true" :showPostponeButton="false"
                                   @clicked-accept="cookieClickedAccept"
                                   @clicked-decline="cookieClickedDecline"
                                   @clicked-postpone="cookieClickedPostpone"
                                   @removed-cookie="cookieRemovedCookie"
                                   @status="cookieStatus"
                                   elementId="cookie-banner"
                                   position="bottom-right"
                                   ref="cookie-banner"
                                   transitionName="slideFromBottom"
                                   type="floating">
            <!-- Optional -->
            <template #message>
                <p class="fw-bolder m-0">This website uses cookies</p>
                <div>We use cookies to ensure you get the best experience on our website.</div>
                <hr>
                <div>
                    <!-- Necessary Cookies -->
                    <div class="bg-white rounded px-3 py-3">
                        <div class="d-flex align-items-center">
                            <div class="flex-fill fw-bolder">
                                Necessary
                            </div>
                            <div class="form-check form-switch">
                                <input v-model="cookies.necessary" class="form-check-input fs-3 pe-pointer m-0" type="checkbox" id="flexSwitchCheckChecked" disabled>
                            </div>
                        </div>
                        <div class="mt-2">Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.</div>
                    </div>
                    <hr>
                    <!-- Preferences Cookies -->
                    <div class="bg-white rounded px-3 py-3">
                        <div class="d-flex align-items-center">
                            <div class="flex-fill fw-bolder">
                                Preferences
                            </div>
                            <div class="form-check form-switch">
                                <input v-model="cookies.preferences" class="form-check-input fs-3 pe-pointer m-0" type="checkbox" id="flexSwitchCheckChecked">
                            </div>
                        </div>
                        <div>Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.</div>
                    </div>
                </div>
                <hr>
                <div>
                    <p class="m-0">Your consent applies to the following domains:</p>
                    <ul>
                        <li><a href="https://www.tapgiant.com" target="_blank">www.tapgiant.com</a></li>
                        <li><a href="https://store.tapgiant.com" target="_blank">store.tapgiant.com</a></li>
                    </ul>
                </div>
                <p class="m-0">Learn more by visiting our <a href="/privacy" target="_blank">Privacy Policy</a> & <a href="/terms">Terms of Service</a>.</p>
                <p class="m-0">Cookie declaration last updated on 20 March 2022 by <a href="https://www.tapgiant.com" target="_blank">Tapgiant</a>.</p>
            </template>

            <!-- Optional -->
            <template #declineContent>Deny</template>

            <!-- Optional -->
            <template #acceptContent>Allow Selection</template>
        </vue-cookie-accept-decline>
    </footer>
</template>

<script>
import {
    COOKIE_CONSENT_NECESSARY,
    COOKIE_CONSENT_PREFERENCES
} from '@/common/constants'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'

export default {
    components: {
        VueCookieAcceptDecline
    },
    data: function () {
        return {
            COOKIE_CONSENT_NECESSARY,
            COOKIE_CONSENT_PREFERENCES,
            cookieStatus: '',
            cookies: {
                necessary: true,
                preferences: true
            }
        }
    },
    computed: {
        year: () => new Date().getFullYear(),
        locale: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        set_locale: function (locale) {
            this.$bus.$emit('App/locale', locale)
        },
        cookieClickedAccept: function () {
            this.$store.commit('SET_COOKIE_CONSENT', {
                cookie_name: COOKIE_CONSENT_NECESSARY,
                cookie_value: this.cookies.necessary ? '1' : '0'
            })
            this.$store.commit('SET_COOKIE_CONSENT', {
                cookie_name: COOKIE_CONSENT_PREFERENCES,
                cookie_value: this.cookies.preferences ? '1' : '0'
            })
        },
        cookieClickedDecline: function () {},
        cookieClickedPostpone: function () {},
        cookieRemovedCookie: function () {}
    }
}
</script>

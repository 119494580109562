/**
 * This pretty doggo is accepting an object with {key: value}
 * It barks for some time then it returns the querystring
 * e.g.
 * {car: 'bmwu', hp: 350, seats=['seat1', 'seat2']}
 * car=bmwu&hp=350&seats=seat1,seat2
 */
export default {
    methods: {
        serialize_query: function (obj) {
            const str = []
            for (const p in obj) {
                if (typeof obj[p] === 'undefined' || obj[p].length <= 0) {
                    continue
                }
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
            }
            return str.join('&')
        }
    }
}

/**
 * This pretty doggo returns an uuidv4
 *
 */
const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'

export default {
    methods: {
        uuidv4: function () {
            return template.replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0
                const v = c === 'x' ? r : (r & 0x3 | 0x8)

                return v.toString(16)
            })
        }
    }
}

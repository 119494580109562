export default function (value, long) {
    if (value) {
        const seconds = Math.floor((new Date() - new Date(value)) / 1000)
        let interval = seconds / 31536000

        if (interval > 1) {
            return Math.floor(interval) + (long ? ' years ago' : 'y')
        }
        interval = seconds / 2592000
        if (interval > 1) {
            return Math.floor(interval) + (long ? ' months ago' : 'M')
        }
        interval = seconds / 86400
        if (interval > 1) {
            return Math.floor(interval) + (long ? ' days ago' : 'd')
        }
        interval = seconds / 3600
        if (interval > 1) {
            return Math.floor(interval) + (long ? ' hours ago' : 'h')
        }
        interval = seconds / 60
        if (interval > 1) {
            return Math.floor(interval) + (long ? ' minutes ago' : 'm')
        }
        return Math.floor(seconds) + (long ? ' seconds ago' : 's')
    }
    return ''
}

<template>
    <div id="notification" class="row">
        <div id="alerts" class="col-12">
            <div v-for="item in items" :key="item.id" class="mt-3">
                <Notification :config="item" />
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Usage
 * this.$bus.$emit('Notification/show', {'msg': i+' Something bad is happening!', 'level': 'success'/'info'/'warning'/'danger'})
 *
 */
import Notification from '@/components/Notification.vue'

export default {
    components: {
        Notification
    },
    data: function () {
        return {
            items: []
        }
    },
    mounted: function () {
        const self = this
        this.$bus.$on('Notification/show', (data) => {
            self.show(data)
        })
        this.$bus.$on('Notification/delete', (data) => {
            self.close(data)
        })
    },
    methods: {
        generate_id: function () {
            let uuid = ''
            for (let i = 0; i < 32; i++) {
                const random = Math.random() * 16 | 0
                if (i === 8 || i === 12 || i === 16 || i === 20) {
                    uuid += '-'
                }
                uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16)
            }
            return uuid
        },
        show: function (data) {
            data.id = this.generate_id()
            this.items.push(data)
        },
        close: function (data) {
            for (let i=0; i<this.items.length; ++i) {
                if (this.items[i].id === data.id) {
                    this.items.splice(i, 1)
                    break
                }
            }
        }
    }
}
</script>

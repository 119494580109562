import {
    ORDERS_PATH
} from '@/common/constants'

import { createStore } from 'vuex'
import axios from 'axios'
import SerializeQuery from '@/common/mixin/SerializeQuery'

const store = {
    state: {
        orders: [],
        paginator: {
            limit: 30,
            count: 0,
            page: 1
        }
    },
    mutations: {
        SET_ORDERS (state, value) {
            state.orders = value
        },
        SET_PAGINATOR (state, value) {
            state.paginator = value
        },
        SET_PAGE (state, value) {
            state.paginator.page = value
        }
    },
    actions: {
        async PAGINATE (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${ORDERS_PATH}?${qs}`)
            } catch (error) {
                return error.response
            }

            context.commit('SET_ORDERS', result.data.items)
            context.commit('SET_PAGINATOR', {
                limit: result.data.limit,
                count: result.data.count,
                page: result.data.page
            })
        },
        async GET_INFO (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${ORDERS_PATH}/delivery/info?${qs}`)
            } catch (error) {
                return error.response
            }
            return result
        },
        async GET_CALENDAR (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${ORDERS_PATH}/calendar?${qs}`)
            } catch (error) {
                return error.response
            }
            return result
        },
        async GET_HISTORY (context, data) {
            const qs = SerializeQuery.methods.serialize_query(data)
            let result
            try {
                result = await axios.get(`${ORDERS_PATH}/history?${qs}`)
            } catch (error) {
                return error.response
            }
            return result
        },
        async GET_ORDER (context, order_id) {
            let result
            try {
                result = await axios.get(`${ORDERS_PATH}/${order_id}`)
            } catch (error) {
                return error.response
            }
            return result
        },
        async CREATE (context, data) {
            let response = {}
            try {
                response = await axios.post(`${ORDERS_PATH}/provision`, data)
            } catch (error) {
                return error.response
            }
            return response
        },
        async UPDATE_ORDER (context, data) {
            let response = {}
            try {
                response = await axios.put(`${ORDERS_PATH}/${data.id}`, data.params)
            } catch (error) {
                return error.response
            }
            return response
        },
        async UPDATE_DELIVERY (context, data) {
            let response = {}
            try {
                response = await axios.put(`${ORDERS_PATH}/${data.id}/delivery`, data.params)
            } catch (error) {
                return error.response.data
            }
            return response.data
        },
        async EMAIL_RECEIPT (context, data) {
            let response = {}
            try {
                response = await axios.post(`${ORDERS_PATH}/${data.id}/email`)
            } catch (error) {
                return error.response
            }
            return response
        },
        async EXPORT_ORDERS (context, data) {
            const opt = {}
            if (data.format === 'xlsx') {
                opt.responseType = 'blob'
            }
            const qs = SerializeQuery.methods.serialize_query(data)
            let response
            try {
                response = await axios.get(`${ORDERS_PATH}/export?${qs}`, opt)
            } catch (error) {
                return error.response
            }
            return response
        }
    },
    strict: process.env.NODE_ENV !== 'production'
}

export default createStore(store)

import { MONTH_SHORT } from '@/common/constants.js'

export default function (value) {
    if (value) {
        const date = new Date(value)
        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        const month = date.getMonth()
        return `${day} ${MONTH_SHORT[month]} ${date.getFullYear()}, ${hours}:${minutes}`
    }
    return ''
}

<template>
    <div :class="[template]" class="alert alert-dismissible py-2 ps-3 pe-10 fade show border rounded shadow-sm" role="alert">
        <button type="button" class="btn-close py-3 px-3" data-bs-dismiss="alert" aria-label="Close" @click="close()"></button>
        <div class="d-flex align-items-start">
            <div class="align-self-center">
                <BootstrapIcon :color="icon_color" :name="icon" :size="26" class="me-3" />
            </div>
            <div class="m-0 noselect small">
                <span v-if="title" class="d-block mb-1 fs-5 fw-light">{{title}}</span>
                <span v-html="msg" class="fs-6"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { ALERT_VALIDITY_MS } from '@/common/constants'
import BootstrapIcon from '@/common/directive/BootstrapIcon'

export default {
    components: {
        BootstrapIcon
    },
    props: {
        config: {
            type: Object,
            default: () => {}
        }
    },
    data: function () {
        return {
            id: '',
            msg: '',
            template: '',
            icon: '',
            icon_color: ''
        }
    },
    created: function () {
        this.id = this.config.id
        this.msg = this.config.msg
        this.title = this.config.title
        switch (this.config.level) {
        case 'success':
            this.template = 'bg-white border-teal-500'
            this.icon = 'check-circle-fill'
            this.icon_color = 'fill-teal-500 text-teal-500'
            break
        case 'info':
            this.template = 'bg-white border-blue-500'
            this.icon = 'exclamation-triangle'
            this.icon_color = 'fill-blue-500 text-blue-500'
            break
        case 'warning':
            this.template = 'bg-white border-yellow-500'
            this.icon = 'question-circle'
            this.icon_color = 'fill-yellow-600 text-yellow-600'
            break
        case 'danger':
            this.template = 'bg-white border-red-500'
            this.icon = 'patch-exclamation'
            this.icon_color = 'fill-red-500 text-red-500'
            break
        }
    },
    mounted: function () {
        const self = this
        setTimeout(() => {
            self.close()
        }, ALERT_VALIDITY_MS)
    },
    methods: {
        close: function () {
            this.$parent.close({ id: this.id })
        }
    }
}
</script>
<template>
    <svg :class="color" class="bi" :width="size" :height="size">
        <use :xlink:href="'/svg/bootstrap-icons.svg#'+name"/>
    </svg>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: ''
        },
        size: {
            type: Number,
            default: 24
        }
    }
}
</script>
